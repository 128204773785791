import React from "react"

import Layoutcs from "../../components/layoutcs/layoutcs"
import SEO from "../../components/seo"

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'

import Footer from "../../components/footer/footer"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMouse, faSearch, faCartPlus, faCheck, faCalendar, faMobile, faStarOfLife, faInfinity, faCode, faPlug, faStar, faUsers, faCircleNotch, faChartBar, faLightbulb, faPollH, faFileImage, faServer } from '@fortawesome/free-solid-svg-icons'
import { faTrello, faAmazon } from '@fortawesome/free-brands-svg-icons'

import "../index.scss"
import "../our-work.scss"

import ImgAppsLogo1 from "../../images/apps/logo-feedtrack-color.svg"
import ImgAppsLogo2 from "../../images/apps/logo-ecim-color.svg"
import ImgAppsLogo3 from "../../images/apps/logo-spring-color.svg"
import ImgAppsLogo4 from "../../images/apps/logo-dmonitor-black.svg"

import ImgAppsFeedtrackMockup1 from "../../images/apps/apps-feedtrack-mockup-1.png"
import ImgAppsFeedtrackMockup2 from "../../images/apps/apps-feedtrack-mockup-2.png"
import ImgAppsFeedtrackMockup3 from "../../images/apps/apps-feedtrack-mockup-3.png"
import VideoAppFeedtrack from "../../videos/ftrs-app-feedtrack.mp4"

import ImgAppsSpringMockup1 from "../../images/apps/apps-spring-mockup-1.png"
import ImgAppsSpringMockup2 from "../../images/apps/apps-spring-mockup-2.png"
import ImgAppsSpringMockup3 from "../../images/apps/apps-spring-mockup-3.png"
import ImgAppsSpringMockup4 from "../../images/apps/apps-spring-mockup-4.jpg"

import ImgAppsEcimMockup1 from "../../images/apps/apps-ecim-mockup-1.jpg"
import ImgAppsEcimMockup2 from "../../images/apps/apps-ecim-mockup-2.jpg"
import ImgAppsEcimMockup3 from "../../images/apps/apps-ecim-mockup-3.jpg"
import ImgAppsEcimMockup4 from "../../images/apps/apps-ecim-mockup-4.jpg"

import ImgAppsDmonitorMockup1 from "../../images/apps/apps-dmonitor-mockup-1.png"
import ImgAppsDmonitorMockup2 from "../../images/apps/apps-dmonitor-mockup-2.png"
import ImgAppsDmonitorMockup3 from "../../images/apps/apps-dmonitor-mockup-3.png"
import ImgAppsDmonitorMockup4 from "../../images/apps/apps-dmonitor-mockup-4.png"

import VideoBg from "../../videos/ftrs-video.mp4"

import VideoApp1 from "../../videos/ftrs-videoapp1.mp4"
import VideoApp2 from "../../videos/ftrs-videoapp2.mp4"
import { faCheckSquare } from "@fortawesome/free-regular-svg-icons"

const OurWork = () => (

    <Layoutcs>
        <SEO title="Reference" description="Společně vytváříme moderní digitální zážitky" />

        <div className="ftrs-background" data-sal="fade" data-sal-duration="2000">
			<video autoPlay="autoplay" muted loop className="ftrs-hero__video ftrs-hero__video--dark">
				<source media="(orientation: landscape)" src={VideoBg} type="video/mp4" />
			</video>
		</div>

        <Container className="ftrs-content ftrs-our-work">

            <h1 data-sal="fade" data-sal-duration="1000" className="ftrs-h1">Společně vytváříme moderní digitální zážitky</h1>

            <Row>
                <Col xs="12" lg="6" xl="6" className="ftrs-our-work__paragraph">
                    <p data-sal="fade" data-sal-duration="1000" className="text-basic">Díky naší dlouholeté zkušenosti se nám podařilo vytvořit několik chytrých a&nbsp;moderních nástrojů, které uživatelům ušetří spoustu práce a&nbsp;navíc jsou snadné k&nbsp;používání.</p>
                </Col>
                <Col xs="12" lg="6" xl="6" className="ftrs-our-work__paragraph">
                    <p data-sal="fade" data-sal-duration="1000" className="text-basic">Řadu let vytváříme, udržujeme a&nbsp;vylepšujeme aplikace pro&nbsp;významné společnosti v&nbsp;odvětví financí, pojišťovnictví, automotive, telco a&nbsp;dalších. Naše produkty bývají zapojeny do&nbsp;základních procesů společností a&nbsp;jako takové vyžadují velmi pečlivý a&nbsp;zodpovědný přístup.</p>
                </Col>
            </Row>

            <p data-sal="fade" data-sal-duration="1000" className="ftrs-h3 ftrs-our-work__h3">Zde je několik příkladů naší práce:</p>

        </Container>

        <div className="ftrs-app">
			<div data-sal="fade" data-sal-duration="1000" className="ftrs-app__header ftrs-app__header--feedtrack"></div>
			<Container>

				<div className="ftrs-app__app-header">
					<img data-sal="fade" data-sal-duration="1000" loading="lazy" src={ImgAppsLogo1} alt="feedTRACK" className="ftrs-app__logo" />
					<div><span className="ftrs-app__tab ftrs-app__tab--feedtrack" data-sal="fade" data-sal-duration="1000"># customer experience</span></div>
				</div>

				<Row>
					<Col xs="12" lg="8" xl="6" data-sal="fade" data-sal-duration="1000">
						<div className="ftrs-app__stage">
							<div>
								<h2 className="ftrs-app__subtitle">Chytrý nástroj pro&nbsp;správu zákaznické zkušenosti, který snadno sbírá informace a&nbsp;poskytuje okamžitou zpětnou vazbu.</h2>
								<ul className="ftrs-app__description">
									<li>smart emailing</li>
									<li>přizpůsobitelné projekty</li>
									<li>okamžité zpracování zpětné vazby</li>
									<li>snadná analýza a&nbsp;reporting</li>
									<li>jazyková lokalizace</li>
									<li>správa úkolů</li>
									<li>správa uživatelských přístupů</li>
									<li>notifikace</li>
									<li>automatizace importu dat</li>
								</ul>
							</div>
						</div>
					</Col>
					<Col xs="12" lg="4" xl="6" data-sal="fade" data-sal-duration="1000">

						<h2 className="ftrs-app__subtitle mb1">popis projektu:</h2>
						Migrace do cloudu byla správnou volbou poté, co&nbsp;množství zpracovávaných dat a&nbsp;různorodost velikostí projektů přesáhly určitou úroveň.
						Cloud nám umožňuje okamžitě škálovat výkon našich aplikací, aniž bychom na&nbsp;ně museli neustále dohlížet.
						Cílem tohoto projektu bylo dát feedTRACKu více prostoru k&nbsp;dýchání a&nbsp;připravit jej na&nbsp;ještě vyšší objemy projektů a&nbsp;dat.
					</Col>
				</Row>

				<div className="ftrs-app__video-stage" data-sal="fade" data-sal-duration="1000">
					<div className="ftrs-app__feedtrack-reference" data-sal="fade" data-sal-duration="1000">
						<div className="ftrs-app__feedtrack-header">Hodnocení zákazníka:</div>
						<div className="ftrs-app__feedtrack-stars">
							<FontAwesomeIcon icon={faStar} className="ftrs-app__feedtrack-star" />
							<FontAwesomeIcon icon={faStar} className="ftrs-app__feedtrack-star" />
							<FontAwesomeIcon icon={faStar} className="ftrs-app__feedtrack-star" />
							<FontAwesomeIcon icon={faStar} className="ftrs-app__feedtrack-star" />
							<FontAwesomeIcon icon={faStar} className="ftrs-app__feedtrack-star" />
						</div>

						<p className="ftrs-app__feedtrack-review">
							Byli jsme zde poprvé, ale určitě ne&nbsp;naposledy! Ostrov byl neuvěřitelný, barvy, oceán a&nbsp;podvodní život byl překrásný.
						</p>
						<p className="ftrs-app__feedtrack-review">
							Personál byl milý a&nbsp;vtřícný, díky moc za&nbsp;jejich péči. Jídlo bylo skvělé, díky šefkuchaři!
						</p>

						<div className="ftrs-app__feedtrack-rating">
							<div className="ftrs-app__feedtrack-small-stars">
								<FontAwesomeIcon icon={faStar} className="ftrs-app__feedtrack-small-star" />
								<FontAwesomeIcon icon={faStar} className="ftrs-app__feedtrack-small-star" />
								<FontAwesomeIcon icon={faStar} className="ftrs-app__feedtrack-small-star" />
								<FontAwesomeIcon icon={faStar} className="ftrs-app__feedtrack-small-star" />
								<FontAwesomeIcon icon={faStar} className="ftrs-app__feedtrack-small-star" />
							</div>
							<div className="ftrs-app__feedtrack-category">ubytování</div>
						</div>

						<div className="ftrs-app__feedtrack-rating">
							<div className="ftrs-app__feedtrack-small-stars">
								<FontAwesomeIcon icon={faStar} className="ftrs-app__feedtrack-small-star" />
								<FontAwesomeIcon icon={faStar} className="ftrs-app__feedtrack-small-star" />
								<FontAwesomeIcon icon={faStar} className="ftrs-app__feedtrack-small-star" />
								<FontAwesomeIcon icon={faStar} className="ftrs-app__feedtrack-small-star" />
								<FontAwesomeIcon icon={faStar} className="ftrs-app__feedtrack-small-star" />
							</div>
							<div className="ftrs-app__feedtrack-category">strava</div>
						</div>

						<div className="ftrs-app__feedtrack-rating">
							<div className="ftrs-app__feedtrack-small-stars">
								<FontAwesomeIcon icon={faStar} className="ftrs-app__feedtrack-small-star" />
								<FontAwesomeIcon icon={faStar} className="ftrs-app__feedtrack-small-star" />
								<FontAwesomeIcon icon={faStar} className="ftrs-app__feedtrack-small-star" />
								<FontAwesomeIcon icon={faStar} className="ftrs-app__feedtrack-small-star" />
								<FontAwesomeIcon icon={faStar} className="ftrs-app__feedtrack-small-star" />
							</div>
							<div className="ftrs-app__feedtrack-category">zábava</div>
						</div>

						<div className="ftrs-app__feedtrack-rating">
							<div className="ftrs-app__feedtrack-small-stars">
								<FontAwesomeIcon icon={faStar} className="ftrs-app__feedtrack-small-star" />
								<FontAwesomeIcon icon={faStar} className="ftrs-app__feedtrack-small-star" />
								<FontAwesomeIcon icon={faStar} className="ftrs-app__feedtrack-small-star" />
								<FontAwesomeIcon icon={faStar} className="ftrs-app__feedtrack-small-star" />
								<FontAwesomeIcon icon={faStar} className="ftrs-app__feedtrack-small-star ftrs-app__feedtrack-small-star--gray" />
							</div>
							<div className="ftrs-app__feedtrack-category">cesta &amp; let</div>
						</div>

					</div>
					<video autoPlay="autoplay" muted loop className="ftrs-app__video-app">
						<source src={VideoApp1} type="video/mp4" />
					</video>
				</div>

				<h2 className="ftrs-app__subtitle mb2 mt2 text-center">poskytované služby:</h2>

				<Row className="mt1 mb2 justify-content-center">
					<Col xs="4" lg="3" xl="2" data-sal="fade" data-sal-duration="1000">
						<div className="ftrs-services-provided__item">
							<div className="ftrs-services-provided__icon ftrs-services-provided__icon--feedtrack">
								<FontAwesomeIcon icon={faInfinity} />
							</div>
							digital partnering
						</div>
					</Col>
					<Col xs="4" lg="3" xl="2" data-sal="fade" data-sal-duration="1000">
						<div className="ftrs-services-provided__item">
							<div className="ftrs-services-provided__icon ftrs-services-provided__icon--feedtrack">
								<FontAwesomeIcon icon={faTrello} />
							</div>
							řízení projektu
						</div>
					</Col>
					<Col xs="4" lg="3" xl="2" data-sal="fade" data-sal-duration="1000">
						<div className="ftrs-services-provided__item">
							<div className="ftrs-services-provided__icon ftrs-services-provided__icon--feedtrack">
								<FontAwesomeIcon icon={faPlug} />
							</div>
							api třetích stran
						</div>
					</Col>
					<Col xs="4" lg="3" xl="2" data-sal="fade" data-sal-duration="1000">
						<div className="ftrs-services-provided__item">
							<div className="ftrs-services-provided__icon ftrs-services-provided__icon--feedtrack">
								<FontAwesomeIcon icon={faAmazon} />
							</div>
							migrace do&nbsp;cloudu
						</div>
					</Col>
					<Col xs="4" lg="3" xl="2" data-sal="fade" data-sal-duration="1000">
						<div className="ftrs-services-provided__item">
							<div className="ftrs-services-provided__icon ftrs-services-provided__icon--feedtrack">
								<FontAwesomeIcon icon={faFileImage} />
							</div>
							design a&nbsp;architektura
						</div>
					</Col>
					<Col xs="4" lg="3" xl="2" data-sal="fade" data-sal-duration="1000">
						<div className="ftrs-services-provided__item">
							<div className="ftrs-services-provided__icon ftrs-services-provided__icon--feedtrack">
								<FontAwesomeIcon icon={faServer} />
							</div>
							devops
						</div>
					</Col>
					<Col xs="4" lg="3" xl="2" data-sal="fade" data-sal-duration="1000">
						<div className="ftrs-services-provided__item">
							<div className="ftrs-services-provided__icon ftrs-services-provided__icon--feedtrack">
								<FontAwesomeIcon icon={faStarOfLife} />
							</div>
							quality assurance
						</div>
					</Col>
					<Col xs="4" lg="3" xl="2" data-sal="fade" data-sal-duration="1000">
						<div className="ftrs-services-provided__item">
							<div className="ftrs-services-provided__icon ftrs-services-provided__icon--feedtrack">
								<FontAwesomeIcon icon={faPollH} />
							</div>
							podpora &amp; údržba
						</div>
					</Col>
					<Col xs="4" lg="3" xl="2" data-sal="fade" data-sal-duration="1000">
						<div className="ftrs-services-provided__item">
							<div className="ftrs-services-provided__icon ftrs-services-provided__icon--feedtrack">
								<FontAwesomeIcon icon={faCode} />
							</div>
							precizní vývoj
						</div>
					</Col>
				</Row>

				<div className="ftrs-app__preview mb1">

					<Row className="justify-content-center">
						<Col xs="4" data-sal="fade" data-sal-duration="1000">
							<div className="ftrs-app__mockup ftrs-app__mockup--iphone">
								<div className="ftrs-app__iphone-part1"></div>
								<div className="ftrs-app__iphone-screen">
									<img src={ImgAppsFeedtrackMockup1} alt="feedTRACK" className="ftrs-app__iphone-image" />
								</div>
								<div className="ftrs-app__iphone-part2"></div>
							</div>
						</Col>
						<Col xs="4" data-sal="fade" data-sal-duration="1000">
							<div className="ftrs-app__mockup ftrs-app__mockup--iphone">
								<div className="ftrs-app__iphone-part1"></div>
								<div className="ftrs-app__iphone-screen">
									<img src={ImgAppsFeedtrackMockup2} alt="feedTRACK" className="ftrs-app__iphone-image" />
								</div>
								<div className="ftrs-app__iphone-part2"></div>
							</div>
						</Col>
						<Col xs="4" data-sal="fade" data-sal-duration="1000">
							<div className="ftrs-app__mockup ftrs-app__mockup--iphone">
								<div className="ftrs-app__iphone-part1"></div>
								<div className="ftrs-app__iphone-screen">
									<img src={ImgAppsFeedtrackMockup3} alt="feedTRACK" className="ftrs-app__iphone-image" />
								</div>
								<div className="ftrs-app__iphone-part2"></div>
							</div>
						</Col>
					</Row>

				</div>

				<Row data-sal="fade" data-sal-duration="1000">
					<Col xs="6" xl="3">
						<div className="ftrs-app__feedtrack-point">
							<div className="ftrs-app__feedtrack-point-icon">
								<FontAwesomeIcon icon={faUsers} />
							</div>
							<div className="ftrs-app__feedtrack-point-header">2.200.000 +</div>
							<div className="ftrs-app__feedtrack-point-description">oslovených zákazníků ročně</div>
						</div>
					</Col>
					<Col xs="6" xl="3">
						<div className="ftrs-app__feedtrack-point">
							<div className="ftrs-app__feedtrack-point-icon">
								<FontAwesomeIcon icon={faCircleNotch} />
							</div>
							<div className="ftrs-app__feedtrack-point-header">až 30 %</div>
							<div className="ftrs-app__feedtrack-point-description">návratnost</div>
						</div>
					</Col>
					<Col xs="6" xl="3">
						<div className="ftrs-app__feedtrack-point">
							<div className="ftrs-app__feedtrack-point-icon">
								<FontAwesomeIcon icon={faChartBar} />
							</div>
							<div className="ftrs-app__feedtrack-point-header">+ 67</div>
							<div className="ftrs-app__feedtrack-point-description">průměrné NPS</div>
						</div>
					</Col>
					<Col xs="6" xl="3">
						<div className="ftrs-app__feedtrack-point">
							<div className="ftrs-app__feedtrack-point-icon">
								<FontAwesomeIcon icon={faLightbulb} />
							</div>
							<div className="ftrs-app__feedtrack-point-header">66000 +</div>
							<div className="ftrs-app__feedtrack-point-description">návrhů na zlepšení ročně</div>
						</div>
					</Col>
				</Row>

				<div className="ftrs-app__preview mb2 mt1">

					<div className="ftrs-app__mockup ftrs-app__mockup--macbook">
						<div className="ftrs-app__macbook-screen">
							<video autoPlay="autoplay" muted loop className="ftrs-app__macbook-video">
								<source src={VideoAppFeedtrack} type="video/mp4" />
							</video>
						</div>
					</div>

				</div>

			</Container>
        </div>

        <div className="ftrs-app">
			<div data-sal="fade" data-sal-duration="1000" className="ftrs-app__header ftrs-app__header--spring"></div>
			<Container>

				<div className="ftrs-app__app-header">
					<img data-sal="fade" data-sal-duration="1000" loading="lazy" src={ImgAppsLogo3} alt="Spring" className="ftrs-app__logo" />
					<div><span className="ftrs-app__tab ftrs-app__tab--spring" data-sal="fade" data-sal-duration="1000"># market research</span></div>
				</div>

				<Row>
					<Col xs="12" lg="8" xl="6" data-sal="fade" data-sal-duration="1000">
						<div className="ftrs-app__stage">
							<div>
								<h2 className="ftrs-app__subtitle mb1">Moderní nástroj pro&nbsp;správu mystery shoppingových projektů.</h2>
								<ul className="ftrs-app__description mt1 mb3">
									<li>webová a mobilní aplikace pro&nbsp;sběr dat</li>
									<li>okamžitý reporting</li>
									<li>GPS lokalizace</li>
									<li>nábor pracovníků</li>
									<li>správu úkolů</li>
									<li>media gallery</li>
									<li>nástroj pro&nbsp;tvorbu dotazníků</li>
									<li>správa odměn</li>
									<li>modul pro&nbsp;kontrolu kvality</li>
									<li>import a&nbsp;export dat</li>
								</ul>
							</div>
						</div>
					</Col>
					<Col xs="12" lg="4" xl="6" data-sal="fade" data-sal-duration="1000">

						<h2 className="ftrs-app__subtitle mb1">popis projektu:</h2>
						<p>Tento projekt začal asi před&nbsp;15 lety se&nbsp;záměrem automatizovat samotné základy odvětví mystery shoppingu.</p>
						<p>Od té doby jsme jej rozšířili na&nbsp;systém, který automatizuje procesy, komunikaci a&nbsp;reporting. Systém již má řadu funkcí a&nbsp;nové funkce přidáváme v&nbsp;každém vývojovém sprintu.</p>
					</Col>
				</Row>

				<div className="ftrs-app__video-stage" data-sal="fade" data-sal-duration="1000">
					<div className="ftrs-app__feedtrack-reference" data-sal="fade" data-sal-duration="1000">
						<div className="ftrs-app__spring-question">Cítili jste ze strany prodejce skutečný zájem o&nbsp;Vaši osobu?</div>
						<div className="ftrs-app__spring-scale">
							<div className="ftrs-app__spring-scale-item">
								<FontAwesomeIcon icon={faCheckSquare} className="ftrs-app__spring-checkbox" />
								ano
							</div>
							<div className="ftrs-app__spring-scale-item ftrs-app__spring-scale-item--active">
								<FontAwesomeIcon icon={faCheckSquare} className="ftrs-app__spring-checkbox ftrs-app__spring-checkbox--active" />
								spíše ano
							</div>
							<div className="ftrs-app__spring-scale-item">
								<FontAwesomeIcon icon={faCheckSquare} className="ftrs-app__spring-checkbox" />
								ani/ani
							</div>
							<div className="ftrs-app__spring-scale-item">
								<FontAwesomeIcon icon={faCheckSquare} className="ftrs-app__spring-checkbox" />
								spíše ne
							</div>
							<div className="ftrs-app__spring-scale-item">
								<FontAwesomeIcon icon={faCheckSquare} className="ftrs-app__spring-checkbox" />
								ne
							</div>
						</div>
					</div>
					<video autoPlay="autoplay" muted loop className="ftrs-app__video-app">
						<source src={VideoApp2} type="video/mp4" />
					</video>
				</div>

				<h2 className="ftrs-app__subtitle mb2 mt2 text-center">poskytované služby:</h2>

				<Row className="mt1 mb2 justify-content-center">
					<Col xs="4" lg="3" xl="2" data-sal="fade" data-sal-duration="1000">
						<div className="ftrs-services-provided__item">
							<div className="ftrs-services-provided__icon ftrs-services-provided__icon--spring">
								<FontAwesomeIcon icon={faInfinity} />
							</div>
							digital partnering
						</div>
					</Col>
					<Col xs="4" lg="3" xl="2" data-sal="fade" data-sal-duration="1000">
						<div className="ftrs-services-provided__item">
							<div className="ftrs-services-provided__icon ftrs-services-provided__icon--spring">
								<FontAwesomeIcon icon={faTrello} />
							</div>
							řízení projektu
						</div>
					</Col>
					<Col xs="4" lg="3" xl="2" data-sal="fade" data-sal-duration="1000">
						<div className="ftrs-services-provided__item">
							<div className="ftrs-services-provided__icon ftrs-services-provided__icon--spring">
								<FontAwesomeIcon icon={faFileImage} />
							</div>
							design a architektura
						</div>
					</Col>
					<Col xs="4" lg="3" xl="2" data-sal="fade" data-sal-duration="1000">
						<div className="ftrs-services-provided__item">
							<div className="ftrs-services-provided__icon ftrs-services-provided__icon--spring">
								<FontAwesomeIcon icon={faCode} />
							</div>
							precizní vývoj
						</div>
					</Col>
					<Col xs="4" lg="3" xl="2" data-sal="fade" data-sal-duration="1000">
						<div className="ftrs-services-provided__item">
							<div className="ftrs-services-provided__icon ftrs-services-provided__icon--spring">
								<FontAwesomeIcon icon={faServer} />
							</div>
							devops
						</div>
					</Col>
					<Col xs="4" lg="3" xl="2" data-sal="fade" data-sal-duration="1000">
						<div className="ftrs-services-provided__item">
							<div className="ftrs-services-provided__icon ftrs-services-provided__icon--spring">
								<FontAwesomeIcon icon={faMobile} />
							</div>
							mobilní aplikace
						</div>
					</Col>
					<Col xs="4" lg="3" xl="2" data-sal="fade" data-sal-duration="1000">
						<div className="ftrs-services-provided__item">
							<div className="ftrs-services-provided__icon ftrs-services-provided__icon--spring">
								<FontAwesomeIcon icon={faPlug} />
							</div>
							api třetích stran
						</div>
					</Col>
					<Col xs="4" lg="3" xl="2" data-sal="fade" data-sal-duration="1000">
						<div className="ftrs-services-provided__item">
							<div className="ftrs-services-provided__icon ftrs-services-provided__icon--spring">
								<FontAwesomeIcon icon={faStarOfLife} />
							</div>
							quality assurance
						</div>
					</Col>
					<Col xs="4" lg="3" xl="2" data-sal="fade" data-sal-duration="1000">
						<div className="ftrs-services-provided__item">
							<div className="ftrs-services-provided__icon ftrs-services-provided__icon--spring">
								<FontAwesomeIcon icon={faPollH} />
							</div>
							podpora &amp; údržba
						</div>
					</Col>
				</Row>

				<div className="ftrs-app__preview mb1">

					<Row className="justify-content-md-center">
						<Col xs="4" data-sal="fade" data-sal-duration="1000">
							<div className="ftrs-app__mockup ftrs-app__mockup--iphone">
								<div className="ftrs-app__iphone-part1"></div>
								<div className="ftrs-app__iphone-screen">
									<img src={ImgAppsSpringMockup1} alt="Spring" className="ftrs-app__iphone-image" />
								</div>
								<div className="ftrs-app__iphone-part2"></div>
							</div>
						</Col>
						<Col xs="4" data-sal="fade" data-sal-duration="1000">
							<div className="ftrs-app__mockup ftrs-app__mockup--iphone">
								<div className="ftrs-app__iphone-part1"></div>
								<div className="ftrs-app__iphone-screen">
									<img src={ImgAppsSpringMockup2} alt="Spring" className="ftrs-app__iphone-image" />
								</div>
								<div className="ftrs-app__iphone-part2"></div>
							</div>
						</Col>
						<Col xs="4" data-sal="fade" data-sal-duration="1000">
							<div className="ftrs-app__mockup ftrs-app__mockup--iphone">
								<div className="ftrs-app__iphone-part1"></div>
								<div className="ftrs-app__iphone-screen">
									<img src={ImgAppsSpringMockup3} alt="Spring" className="ftrs-app__iphone-image" />
								</div>
								<div className="ftrs-app__iphone-part2"></div>
							</div>
						</Col>
					</Row>

				</div>

				<Row data-sal="fade" data-sal-duration="1000">
					<Col xs="6" xl="3">
						<div className="ftrs-app__feedtrack-point">
							<div className="ftrs-app__feedtrack-point-icon">
								<FontAwesomeIcon icon={faCartPlus} />
							</div>
							<div className="ftrs-app__feedtrack-point-header">500.000 +</div>
							<div className="ftrs-app__feedtrack-point-description">provedených mystery shoppingů</div>
						</div>
					</Col>
					<Col xs="6" xl="3">
						<div className="ftrs-app__feedtrack-point">
							<div className="ftrs-app__feedtrack-point-icon">
								<FontAwesomeIcon icon={faUsers} />
							</div>
							<div className="ftrs-app__feedtrack-point-header">10.000 +</div>
							<div className="ftrs-app__feedtrack-point-description">uživatelů</div>
						</div>
					</Col>
					<Col xs="6" xl="3">
						<div className="ftrs-app__feedtrack-point">
							<div className="ftrs-app__feedtrack-point-icon">
								<FontAwesomeIcon icon={faCheck} />
							</div>
							<div className="ftrs-app__feedtrack-point-header">40.000 +</div>
							<div className="ftrs-app__feedtrack-point-description">vyplněných formulářů ročně</div>
						</div>
					</Col>
					<Col xs="6" xl="3">
						<div className="ftrs-app__feedtrack-point">
							<div className="ftrs-app__feedtrack-point-icon">
								<FontAwesomeIcon icon={faCalendar} />
							</div>
							<div className="ftrs-app__feedtrack-point-header">20 +</div>
							<div className="ftrs-app__feedtrack-point-description">let provozu</div>
						</div>
					</Col>
				</Row>

				<div className="ftrs-app__preview mb2 mt1">

					<div className="ftrs-app__mockup ftrs-app__mockup--macbook">
						<div className="ftrs-app__macbook-screen">
							<img src={ImgAppsSpringMockup4} alt="Spring" className="ftrs-app__iphone-image" />
						</div>
					</div>

				</div>

			</Container>
        </div>

        <div className="ftrs-app">
			<div data-sal="fade" data-sal-duration="1000" className="ftrs-app__header ftrs-app__header--ecim"></div>
			<Container>

				<div className="ftrs-app__app-header">
					<img data-sal="fade" data-sal-duration="1000" loading="lazy" src={ImgAppsLogo2} alt="eCIM" className="ftrs-app__logo" />
					<div><span className="ftrs-app__tab ftrs-app__tab--ecim" data-sal="fade" data-sal-duration="1000"># market intelligence</span></div>
				</div>

				<Row>
					<Col xs="12" lg="8" xl="6" data-sal="fade" data-sal-duration="1000">
						<h2 className="ftrs-app__subtitle">Ve svém oboru vedoucí platforma, která pomáhá nadnárodním korporacím být krok před konkurenty.</h2>
						<ul className="ftrs-app__description">
							<li>zprávy</li>
							<li>videa</li>
							<li>social networks</li>
							<li>obrázky</li>
							<li>analýzy</li>
							<li>emailové notifikace</li>
							<li>newslettery</li>
							<li>data a grafy</li>
							<li>správa uživatelů</li>
							<li>fulltextové vyhledávání</li>
							<li>kategorizace obsahu</li>
							<li>statistiky využívání</li>
						</ul>
					</Col>
					<Col xs="12" lg="4" xl="6" data-sal="fade" data-sal-duration="1000">
						<h2 className="ftrs-app__subtitle mb1">popis projektu:</h2>
						<p>Cílem eCIMu bylo vytvořit platformu pro&nbsp;shromažďování velkého množství informací a&nbsp;zároveň zachovat jejich organizaci a&nbsp;přehlednost tak, aby&nbsp;uživatelé mohli snadno na&nbsp;pár kliknutí najít to, co&nbsp;vvzrovna hledají.</p>
						<p>V eCIMu najdete jehlu v&nbsp;kupě dat během pár&nbsp;sekund.</p>
					</Col>
				</Row>

				<div className="ftrs-app__preview mt3 mb3">

					<Row className="justify-content-md-center">
						<Col xs="4" data-sal="fade" data-sal-duration="1000">
							<div className="ftrs-app__mockup ftrs-app__mockup--iphone">
								<div className="ftrs-app__iphone-part1"></div>
								<div className="ftrs-app__iphone-screen">
									<img src={ImgAppsEcimMockup1} alt="eCIM" className="ftrs-app__iphone-image" />
								</div>
								<div className="ftrs-app__iphone-part2"></div>
							</div>
						</Col>
						<Col xs="4" data-sal="fade" data-sal-duration="1000">
							<div className="ftrs-app__mockup ftrs-app__mockup--iphone">
								<div className="ftrs-app__iphone-part1"></div>
								<div className="ftrs-app__iphone-screen">
									<img src={ImgAppsEcimMockup2} alt="eCIM" className="ftrs-app__iphone-image" />
								</div>
								<div className="ftrs-app__iphone-part2"></div>
							</div>
						</Col>
						<Col xs="4" data-sal="fade" data-sal-duration="1000">
							<div className="ftrs-app__mockup ftrs-app__mockup--iphone">
								<div className="ftrs-app__iphone-part1"></div>
								<div className="ftrs-app__iphone-screen">
									<img src={ImgAppsEcimMockup3} alt="eCIM" className="ftrs-app__iphone-image" />
								</div>
								<div className="ftrs-app__iphone-part2"></div>
							</div>
						</Col>
					</Row>

				</div>

				<h2 className="ftrs-app__subtitle mb2 mt2 text-center">poskytované služby:</h2>

				<Row className="mt1 mb2 justify-content-center">
					<Col xs="4" lg="3" xl="2" data-sal="fade" data-sal-duration="1000">
						<div className="ftrs-services-provided__item">
							<div className="ftrs-services-provided__icon ftrs-services-provided__icon--ecim">
								<FontAwesomeIcon icon={faInfinity} />
							</div>
							digital partnering
						</div>
					</Col>
					<Col xs="4" lg="3" xl="2" data-sal="fade" data-sal-duration="1000">
						<div className="ftrs-services-provided__item">
							<div className="ftrs-services-provided__icon ftrs-services-provided__icon--ecim">
								<FontAwesomeIcon icon={faTrello} />
							</div>
							řízení projektu
						</div>
					</Col>
					<Col xs="4" lg="3" xl="2" data-sal="fade" data-sal-duration="1000">
						<div className="ftrs-services-provided__item">
							<div className="ftrs-services-provided__icon ftrs-services-provided__icon--ecim">
								<FontAwesomeIcon icon={faFileImage} />
							</div>
							design a architektura
						</div>
					</Col>
					<Col xs="4" lg="3" xl="2" data-sal="fade" data-sal-duration="1000">
						<div className="ftrs-services-provided__item">
							<div className="ftrs-services-provided__icon ftrs-services-provided__icon--ecim">
								<FontAwesomeIcon icon={faCode} />
							</div>
							precizní vývoj
						</div>
					</Col>
					<Col xs="4" lg="3" xl="2" data-sal="fade" data-sal-duration="1000">
						<div className="ftrs-services-provided__item">
							<div className="ftrs-services-provided__icon ftrs-services-provided__icon--ecim">
								<FontAwesomeIcon icon={faServer} />
							</div>
							devops
						</div>
					</Col>
					<Col xs="4" lg="3" xl="2" data-sal="fade" data-sal-duration="1000">
						<div className="ftrs-services-provided__item">
							<div className="ftrs-services-provided__icon ftrs-services-provided__icon--ecim">
								<FontAwesomeIcon icon={faStarOfLife} />
							</div>
							quality assurance
						</div>
					</Col>
					<Col xs="4" lg="3" xl="2" data-sal="fade" data-sal-duration="1000">
						<div className="ftrs-services-provided__item">
							<div className="ftrs-services-provided__icon ftrs-services-provided__icon--ecim">
								<FontAwesomeIcon icon={faPollH} />
							</div>
							podpora &amp; údržba
						</div>
					</Col>
				</Row>

				<div className="ftrs-app__preview mb2 mt3">

					<div className="ftrs-app__mockup ftrs-app__mockup--macbook">
						<div className="ftrs-app__macbook-screen">
							<img src={ImgAppsEcimMockup4} alt="eCIM" className="ftrs-app__iphone-image" />
						</div>
					</div>

				</div>

				<Row data-sal="fade" data-sal-duration="1000">
					<Col xs="6" xl="3">
						<div className="ftrs-app__feedtrack-point">
							<div className="ftrs-app__feedtrack-point-icon">
								<FontAwesomeIcon icon={faUsers} />
							</div>
							<div className="ftrs-app__feedtrack-point-header">7.000 +</div>
							<div className="ftrs-app__feedtrack-point-description">registrovaných uživatelů</div>
						</div>
					</Col>
					<Col xs="6" xl="3">
						<div className="ftrs-app__feedtrack-point">
							<div className="ftrs-app__feedtrack-point-icon">
								<FontAwesomeIcon icon={faSearch} />
							</div>
							<div className="ftrs-app__feedtrack-point-header">1.000 +</div>
							<div className="ftrs-app__feedtrack-point-description">monitorovaných společností</div>
						</div>
					</Col>
					<Col xs="6" xl="3">
						<div className="ftrs-app__feedtrack-point">
							<div className="ftrs-app__feedtrack-point-icon">
								<FontAwesomeIcon icon={faMouse} />
							</div>
							<div className="ftrs-app__feedtrack-point-header">4.500 +</div>
							<div className="ftrs-app__feedtrack-point-description">hodin vývoje</div>
						</div>
					</Col>
					<Col xs="6" xl="3">
					<div className="ftrs-app__feedtrack-point">
							<div className="ftrs-app__feedtrack-point-icon">
								<FontAwesomeIcon icon={faCalendar} />
							</div>
							<div className="ftrs-app__feedtrack-point-header">13 +</div>
							<div className="ftrs-app__feedtrack-point-description">let provozu</div>
						</div>
					</Col>
				</Row>

			</Container>
        </div>

        <div className="ftrs-app">
			<div data-sal="fade" data-sal-duration="1000" className="ftrs-app__header ftrs-app__header--dmonitor"></div>
			<Container>

				<div className="ftrs-app__app-header">
					<img data-sal="fade" data-sal-duration="1000" loading="lazy" src={ImgAppsLogo4} alt="dMonitor" className="ftrs-app__logo" />
					<div><span className="ftrs-app__tab ftrs-app__tab--dmonitor" data-sal="fade" data-sal-duration="1000"># media monitoring</span></div>
				</div>

				<Row>
					<Col xs="12" lg="8" xl="6" data-sal="fade" data-sal-duration="1000">
						<h2 className="ftrs-app__subtitle">Nástroj pro monitoring médií slouží společnostem ke&nbsp;sledování konkurenčního marketingu jejich konkurence.</h2>
						<ul className="ftrs-app__description">
							<li>správa panelů</li>
							<li>obrázky</li>
							<li>videa</li>
							<li>social networks</li>
							<li>analýzy</li>
							<li>emailové notifikace</li>
							<li>newslettery</li>
							<li>správa uživatelů</li>
							<li>kategorizace obsahu</li>
							<li>statistiky využívání</li>
						</ul>
					</Col>
					<Col xs="12" lg="4" xl="6" data-sal="fade" data-sal-duration="1000">
						<h2 className="ftrs-app__subtitle mb1">popis projektu:</h2>
						<p>V rámci projektu dMonitor se nám podařilo kompletně aktualizovat a&nbsp;stabilizovat dávno překonanou, ale&nbsp;velmi užitečnou starší aplikaci.</p>
						<p>Velmi hladce a&nbsp;bez jediného výpadku pro&nbsp;uživatele jsme aplikaci kompletně přestavěli a&nbsp;dokonce přidali nové funkce.</p>
					</Col>
				</Row>

				<div className="ftrs-app__preview mt3 mb3">

					<Row className="justify-content-md-center">
						<Col xs="4" data-sal="fade" data-sal-duration="1000">
							<div className="ftrs-app__mockup ftrs-app__mockup--iphone">
								<div className="ftrs-app__iphone-part1"></div>
								<div className="ftrs-app__iphone-screen">
									<img src={ImgAppsDmonitorMockup1} alt="dMonitor" className="ftrs-app__iphone-image" />
								</div>
								<div className="ftrs-app__iphone-part2"></div>
							</div>
						</Col>
						<Col xs="4" data-sal="fade" data-sal-duration="1000">
							<div className="ftrs-app__mockup ftrs-app__mockup--iphone">
								<div className="ftrs-app__iphone-part1"></div>
								<div className="ftrs-app__iphone-screen">
									<img src={ImgAppsDmonitorMockup2} alt="dMonitor" className="ftrs-app__iphone-image" />
								</div>
								<div className="ftrs-app__iphone-part2"></div>
							</div>
						</Col>
						<Col xs="4" data-sal="fade" data-sal-duration="1000">
							<div className="ftrs-app__mockup ftrs-app__mockup--iphone">
								<div className="ftrs-app__iphone-part1"></div>
								<div className="ftrs-app__iphone-screen">
									<img src={ImgAppsDmonitorMockup3} alt="dMonitor" className="ftrs-app__iphone-image" />
								</div>
								<div className="ftrs-app__iphone-part2"></div>
							</div>
						</Col>
					</Row>

				</div>

				<h2 className="ftrs-app__subtitle mb2 mt2 text-center">poskytované služby:</h2>

				<Row className="mt1 mb2 justify-content-center">
					<Col xs="4" lg="3" xl="2" data-sal="fade" data-sal-duration="1000">
						<div className="ftrs-services-provided__item">
							<div className="ftrs-services-provided__icon ftrs-services-provided__icon--dmonitor">
								<FontAwesomeIcon icon={faInfinity} />
							</div>
							digital partnering
						</div>
					</Col>
					<Col xs="4" lg="3" xl="2" data-sal="fade" data-sal-duration="1000">
						<div className="ftrs-services-provided__item">
							<div className="ftrs-services-provided__icon ftrs-services-provided__icon--dmonitor">
								<FontAwesomeIcon icon={faTrello} />
							</div>
							řízení projektu
						</div>
					</Col>
					<Col xs="4" lg="3" xl="2" data-sal="fade" data-sal-duration="1000">
						<div className="ftrs-services-provided__item">
							<div className="ftrs-services-provided__icon ftrs-services-provided__icon--dmonitor">
								<FontAwesomeIcon icon={faFileImage} />
							</div>
							design a architektura
						</div>
					</Col>
					<Col xs="4" lg="3" xl="2" data-sal="fade" data-sal-duration="1000">
						<div className="ftrs-services-provided__item">
							<div className="ftrs-services-provided__icon ftrs-services-provided__icon--dmonitor	">
								<FontAwesomeIcon icon={faCode} />
							</div>
							precizní vývoj
						</div>
					</Col>
					<Col xs="4" lg="3" xl="2" data-sal="fade" data-sal-duration="1000">
						<div className="ftrs-services-provided__item">
							<div className="ftrs-services-provided__icon ftrs-services-provided__icon--dmonitor">
								<FontAwesomeIcon icon={faServer} />
							</div>
							devops
						</div>
					</Col>
					<Col xs="4" lg="3" xl="2" data-sal="fade" data-sal-duration="1000">
						<div className="ftrs-services-provided__item">
							<div className="ftrs-services-provided__icon ftrs-services-provided__icon--dmonitor">
								<FontAwesomeIcon icon={faStarOfLife} />
							</div>
							quality assurance
						</div>
					</Col>
					<Col xs="4" lg="3" xl="2" data-sal="fade" data-sal-duration="1000">
						<div className="ftrs-services-provided__item">
							<div className="ftrs-services-provided__icon ftrs-services-provided__icon--dmonitor">
								<FontAwesomeIcon icon={faPollH} />
							</div>
							podpora &amp; údržba
						</div>
					</Col>
				</Row>

				<div className="ftrs-app__preview mb2 mt3">

					<div className="ftrs-app__mockup ftrs-app__mockup--macbook">
						<div className="ftrs-app__macbook-screen">
							<img src={ImgAppsDmonitorMockup4} alt="dMonitor" className="ftrs-app__iphone-image" />
						</div>
					</div>

				</div>

			</Container>
        </div>

		<Footer lang="cs"></Footer>

    </Layoutcs>

)

export default OurWork